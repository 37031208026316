import image_A1_1Nouns_1_png from './assets/images/A1/1Nouns/1.png';
import image_A1_1Nouns_2_png from './assets/images/A1/1Nouns/2.png';
import image_A1_1Nouns_3_png from './assets/images/A1/1Nouns/3.png';
import image_A1_1Nouns_4_png from './assets/images/A1/1Nouns/4.png';
import image_A1_1Nouns_5_png from './assets/images/A1/1Nouns/5.png';
import image_A1_1Nouns_6_png from './assets/images/A1/1Nouns/6.png';
import image_A1_1Nouns_7_png from './assets/images/A1/1Nouns/7.png';
import image_A1_2Adjectives_1_png from './assets/images/A1/2Adjectives/1.png';
import image_A1_2Adjectives_2_png from './assets/images/A1/2Adjectives/2.png';
import image_A1_2Adjectives_3_png from './assets/images/A1/2Adjectives/3.png';
import image_A1_2Adjectives_4_png from './assets/images/A1/2Adjectives/4.png';
import image_A1_2Adjectives_5_png from './assets/images/A1/2Adjectives/5.png';
import image_A1_2Adjectives_6_png from './assets/images/A1/2Adjectives/6.png';
import image_A1_2Adjectives_7_png from './assets/images/A1/2Adjectives/7.png';
import image_A1_2Adjectives_8_png from './assets/images/A1/2Adjectives/8.png';
import image_A1_3Verbs_1_png from './assets/images/A1/3Verbs/1.png';
import image_A1_3Verbs_2_png from './assets/images/A1/3Verbs/2.png';
import image_A1_3Verbs_3_png from './assets/images/A1/3Verbs/3.png';
import image_A1_3Verbs_4_png from './assets/images/A1/3Verbs/4.png';
import image_A1_3Verbs_5_png from './assets/images/A1/3Verbs/5.png';
import image_A1_3Verbs_6_png from './assets/images/A1/3Verbs/6.png';
import image_A1_3Verbs_7_png from './assets/images/A1/3Verbs/7.png';
import image_A1_3Verbs_8_png from './assets/images/A1/3Verbs/8.png';
import image_A1_3Verbs_9_png from './assets/images/A1/3Verbs/9.png';
import image_A1_4There_1_png from './assets/images/A1/4There/1.png';
import image_A1_4There_2_png from './assets/images/A1/4There/2.png';
import image_A1_4There_3_png from './assets/images/A1/4There/3.png';
import image_A1_4There_4_png from './assets/images/A1/4There/4.png';
import image_A2_1PastCont_1_png from './assets/images/A2/1PastCont/1.png';
import image_A2_1PastCont_2_png from './assets/images/A2/1PastCont/2.png';
import image_A2_1PastCont_3_png from './assets/images/A2/1PastCont/3.png';
import image_A2_1PastCont_4_png from './assets/images/A2/1PastCont/4.png';
import image_A2_1PastCont_5_png from './assets/images/A2/1PastCont/5.png';
import image_A2_1PastCont_6_png from './assets/images/A2/1PastCont/6.png';
import image_A2_1PastCont_7_png from './assets/images/A2/1PastCont/7.png';
import image_A2_2Future_1_png from './assets/images/A2/2Future/1.png';
import image_A2_2Future_2_png from './assets/images/A2/2Future/2.png';
import image_A2_2Future_3_png from './assets/images/A2/2Future/3.png';
import image_A2_2Future_4_png from './assets/images/A2/2Future/4.png';
import image_A2_2Future_5_png from './assets/images/A2/2Future/5.png';
import image_A2_2Future_6_png from './assets/images/A2/2Future/6.png';
import image_A2_2Future_7_png from './assets/images/A2/2Future/7.png';
import image_A2_3GoingTo_1_png from './assets/images/A2/3GoingTo/1.png';
import image_A2_3GoingTo_2_png from './assets/images/A2/3GoingTo/2.png';
import image_A2_3GoingTo_3_png from './assets/images/A2/3GoingTo/3.png';
import image_A2_3GoingTo_4_png from './assets/images/A2/3GoingTo/4.png';
import image_A2_3GoingTo_5_png from './assets/images/A2/3GoingTo/5.png';
import image_A2_3GoingTo_6_png from './assets/images/A2/3GoingTo/6.png';
import image_A2_3GoingTo_7_png from './assets/images/A2/3GoingTo/7.png';
import image_A2_4CompSupe_1_png from './assets/images/A2/4CompSupe/1.png';
import image_A2_4CompSupe_2_png from './assets/images/A2/4CompSupe/2.png';
import image_A2_4CompSupe_3_png from './assets/images/A2/4CompSupe/3.png';
import image_A2_4CompSupe_4_png from './assets/images/A2/4CompSupe/4.png';
import image_A2_4CompSupe_5_png from './assets/images/A2/4CompSupe/5.png';
import image_A2_4CompSupe_6_png from './assets/images/A2/4CompSupe/6.png';
import image_A2_4CompSupe_7_png from './assets/images/A2/4CompSupe/7.png';
import image_B1_1PresPerfCont_1_png from './assets/images/B1/1PresPerfCont/1.png';
import image_B1_1PresPerfCont_2_png from './assets/images/B1/1PresPerfCont/2.png';
import image_B1_1PresPerfCont_3_png from './assets/images/B1/1PresPerfCont/3.png';
import image_B1_1PresPerfCont_4_png from './assets/images/B1/1PresPerfCont/4.png';
import image_B1_1PresPerfCont_5_png from './assets/images/B1/1PresPerfCont/5.png';
import image_B1_1PresPerfCont_6_png from './assets/images/B1/1PresPerfCont/6.png';
import image_B1_2PastPerfCont_1_png from './assets/images/B1/2PastPerfCont/1.png';
import image_B1_2PastPerfCont_2_png from './assets/images/B1/2PastPerfCont/2.png';
import image_B1_2PastPerfCont_3_png from './assets/images/B1/2PastPerfCont/3.png';
import image_B1_2PastPerfCont_4_png from './assets/images/B1/2PastPerfCont/4.png';
import image_B1_2PastPerfCont_5_png from './assets/images/B1/2PastPerfCont/5.png';
import image_B1_2PastPerfCont_6_png from './assets/images/B1/2PastPerfCont/6.png';
import image_B1_2PastPerfCont_7_png from './assets/images/B1/2PastPerfCont/7.png';
import image_B1_2PastPerfCont_8_png from './assets/images/B1/2PastPerfCont/8.png';
import image_B1_3SecCond_1_png from './assets/images/B1/3SecCond/1.png';
import image_B1_3SecCond_2_png from './assets/images/B1/3SecCond/2.png';
import image_B1_3SecCond_3_png from './assets/images/B1/3SecCond/3.png';
import image_B1_4ModalVerbs_1_png from './assets/images/B1/4ModalVerbs/1.png';
import image_B1_4ModalVerbs_2_png from './assets/images/B1/4ModalVerbs/2.png';
import image_B2_1MixedCond_1_png from './assets/images/B2/1MixedCond/1.png';
import image_B2_1MixedCond_2_png from './assets/images/B2/1MixedCond/2.png';
import image_B2_1MixedCond_3_png from './assets/images/B2/1MixedCond/3.png';
import image_B2_2Causitives_1_png from './assets/images/B2/2Causitives/1.png';
import image_B2_3ModalsProb_1_png from './assets/images/B2/3ModalsProb/1.png';
import image_B2_3ModalsProb_2_png from './assets/images/B2/3ModalsProb/2.png';
import image_B2_4FuturePerf_1_png from './assets/images/B2/4FuturePerf/1.png';
import image_B2_4FuturePerf_2_png from './assets/images/B2/4FuturePerf/2.png';
import image_B2_4FuturePerf_3_png from './assets/images/B2/4FuturePerf/3.png';
import image_B2_4FuturePerf_4_png from './assets/images/B2/4FuturePerf/4.png';
import image_B2_4FuturePerf_5_png from './assets/images/B2/4FuturePerf/5.png';
import image_Cover_png from './assets/images/Cover.png';
const imageMap = {
  'A1/1Nouns/1.png': image_A1_1Nouns_1_png,
  'A1/1Nouns/2.png': image_A1_1Nouns_2_png,
  'A1/1Nouns/3.png': image_A1_1Nouns_3_png,
  'A1/1Nouns/4.png': image_A1_1Nouns_4_png,
  'A1/1Nouns/5.png': image_A1_1Nouns_5_png,
  'A1/1Nouns/6.png': image_A1_1Nouns_6_png,
  'A1/1Nouns/7.png': image_A1_1Nouns_7_png,
  'A1/2Adjectives/1.png': image_A1_2Adjectives_1_png,
  'A1/2Adjectives/2.png': image_A1_2Adjectives_2_png,
  'A1/2Adjectives/3.png': image_A1_2Adjectives_3_png,
  'A1/2Adjectives/4.png': image_A1_2Adjectives_4_png,
  'A1/2Adjectives/5.png': image_A1_2Adjectives_5_png,
  'A1/2Adjectives/6.png': image_A1_2Adjectives_6_png,
  'A1/2Adjectives/7.png': image_A1_2Adjectives_7_png,
  'A1/2Adjectives/8.png': image_A1_2Adjectives_8_png,
  'A1/3Verbs/1.png': image_A1_3Verbs_1_png,
  'A1/3Verbs/2.png': image_A1_3Verbs_2_png,
  'A1/3Verbs/3.png': image_A1_3Verbs_3_png,
  'A1/3Verbs/4.png': image_A1_3Verbs_4_png,
  'A1/3Verbs/5.png': image_A1_3Verbs_5_png,
  'A1/3Verbs/6.png': image_A1_3Verbs_6_png,
  'A1/3Verbs/7.png': image_A1_3Verbs_7_png,
  'A1/3Verbs/8.png': image_A1_3Verbs_8_png,
  'A1/3Verbs/9.png': image_A1_3Verbs_9_png,
  'A1/4There/1.png': image_A1_4There_1_png,
  'A1/4There/2.png': image_A1_4There_2_png,
  'A1/4There/3.png': image_A1_4There_3_png,
  'A1/4There/4.png': image_A1_4There_4_png,
  'A2/1PastCont/1.png': image_A2_1PastCont_1_png,
  'A2/1PastCont/2.png': image_A2_1PastCont_2_png,
  'A2/1PastCont/3.png': image_A2_1PastCont_3_png,
  'A2/1PastCont/4.png': image_A2_1PastCont_4_png,
  'A2/1PastCont/5.png': image_A2_1PastCont_5_png,
  'A2/1PastCont/6.png': image_A2_1PastCont_6_png,
  'A2/1PastCont/7.png': image_A2_1PastCont_7_png,
  'A2/2Future/1.png': image_A2_2Future_1_png,
  'A2/2Future/2.png': image_A2_2Future_2_png,
  'A2/2Future/3.png': image_A2_2Future_3_png,
  'A2/2Future/4.png': image_A2_2Future_4_png,
  'A2/2Future/5.png': image_A2_2Future_5_png,
  'A2/2Future/6.png': image_A2_2Future_6_png,
  'A2/2Future/7.png': image_A2_2Future_7_png,
  'A2/3GoingTo/1.png': image_A2_3GoingTo_1_png,
  'A2/3GoingTo/2.png': image_A2_3GoingTo_2_png,
  'A2/3GoingTo/3.png': image_A2_3GoingTo_3_png,
  'A2/3GoingTo/4.png': image_A2_3GoingTo_4_png,
  'A2/3GoingTo/5.png': image_A2_3GoingTo_5_png,
  'A2/3GoingTo/6.png': image_A2_3GoingTo_6_png,
  'A2/3GoingTo/7.png': image_A2_3GoingTo_7_png,
  'A2/4CompSupe/1.png': image_A2_4CompSupe_1_png,
  'A2/4CompSupe/2.png': image_A2_4CompSupe_2_png,
  'A2/4CompSupe/3.png': image_A2_4CompSupe_3_png,
  'A2/4CompSupe/4.png': image_A2_4CompSupe_4_png,
  'A2/4CompSupe/5.png': image_A2_4CompSupe_5_png,
  'A2/4CompSupe/6.png': image_A2_4CompSupe_6_png,
  'A2/4CompSupe/7.png': image_A2_4CompSupe_7_png,
  'B1/1PresPerfCont/1.png': image_B1_1PresPerfCont_1_png,
  'B1/1PresPerfCont/2.png': image_B1_1PresPerfCont_2_png,
  'B1/1PresPerfCont/3.png': image_B1_1PresPerfCont_3_png,
  'B1/1PresPerfCont/4.png': image_B1_1PresPerfCont_4_png,
  'B1/1PresPerfCont/5.png': image_B1_1PresPerfCont_5_png,
  'B1/1PresPerfCont/6.png': image_B1_1PresPerfCont_6_png,
  'B1/2PastPerfCont/1.png': image_B1_2PastPerfCont_1_png,
  'B1/2PastPerfCont/2.png': image_B1_2PastPerfCont_2_png,
  'B1/2PastPerfCont/3.png': image_B1_2PastPerfCont_3_png,
  'B1/2PastPerfCont/4.png': image_B1_2PastPerfCont_4_png,
  'B1/2PastPerfCont/5.png': image_B1_2PastPerfCont_5_png,
  'B1/2PastPerfCont/6.png': image_B1_2PastPerfCont_6_png,
  'B1/2PastPerfCont/7.png': image_B1_2PastPerfCont_7_png,
  'B1/2PastPerfCont/8.png': image_B1_2PastPerfCont_8_png,
  'B1/3SecCond/1.png': image_B1_3SecCond_1_png,
  'B1/3SecCond/2.png': image_B1_3SecCond_2_png,
  'B1/3SecCond/3.png': image_B1_3SecCond_3_png,
  'B1/4ModalVerbs/1.png': image_B1_4ModalVerbs_1_png,
  'B1/4ModalVerbs/2.png': image_B1_4ModalVerbs_2_png,
  'B2/1MixedCond/1.png': image_B2_1MixedCond_1_png,
  'B2/1MixedCond/2.png': image_B2_1MixedCond_2_png,
  'B2/1MixedCond/3.png': image_B2_1MixedCond_3_png,
  'B2/2Causitives/1.png': image_B2_2Causitives_1_png,
  'B2/3ModalsProb/1.png': image_B2_3ModalsProb_1_png,
  'B2/3ModalsProb/2.png': image_B2_3ModalsProb_2_png,
  'B2/4FuturePerf/1.png': image_B2_4FuturePerf_1_png,
  'B2/4FuturePerf/2.png': image_B2_4FuturePerf_2_png,
  'B2/4FuturePerf/3.png': image_B2_4FuturePerf_3_png,
  'B2/4FuturePerf/4.png': image_B2_4FuturePerf_4_png,
  'B2/4FuturePerf/5.png': image_B2_4FuturePerf_5_png,
  'Cover.png': image_Cover_png,
};

export default imageMap;
